import request from '@/utils/request'
import { getToken, setToken, removeToken } from '@/utils/auth'

export function getCartList(query) {
  if(!getToken()){
    return
  }
  return request({
    url: '/cart/getList',
    method: 'get',
    params:query
  })
}

export function getCartGoodsCount(query) {
  if(!getToken()){
    return
  }
  return request({
    url: '/cart/getList',
    method: 'get',
    params:query
  })
}

export function addCart(data) {
  return request({
    url: '/cart',
    method: 'post',
    data
  })
}

export function addDefaultGoodsProduct(data) {
  return request({
    url: '/cart/addDefaultGoodsProduct',
    method: 'post',
    data
  })
}

export function updateCart(data) {
  return request({
    url: '/cart',
    method: 'put',
    data
  })
}

export function changeNumber(cartId, number) {
  return request({
    url: `/cart/changeNum/${cartId}/${number}`,
    method: 'post'
  })
}

export function addNumber(cartId, number) {
  return request({
    url: `/cart/addNum/${cartId}/${number}`,
    method: 'post'
  })
}

export function minusNumber(cartId, number) {
  return request({
    url: `/cart/minusNum/${cartId}/${number}`,
    method: 'post'
  })
}

export function deleteCart(data) {
  return request({
    url: 'cart/delete',
    method: 'post',
    data
  })
}

export function getCheckedGoods() {
  return request({
    url: '/cart/getCheckedGoods',
    method: 'post'
  })
}
