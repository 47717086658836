import request from '@/utils/request'

export function getAddress(query) {
  return request({
    url: '/address/getList',
    method: 'get',
    params: query
  })
}

export function addAddress(data) {
  return request({
    url: '/address/save',
    method: 'post',
    data
  })
}

export function updateAddress(data) {
  return request({
    url: '/address',
    method: 'put',
    data
  })
}

export function deleteAddress(data) {
  return request({
    url: `/address/delete`,
    method: 'post',
    data
  })
}
