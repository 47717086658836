import { getAddress } from '@/api/address'
let xiangqing = JSON.parse(localStorage.getItem('xiangqing'))||{}
const state = {
  addressList: [],
  selectedAddress: {},
  xiangqing:xiangqing,
  GWCNum:0
}

const mutations = {
  SET_ADDRESS_LIST: (state, list) => {
    state.addressList = list
  },
  SET_SELECTED_ADDRESS: (state, item) => {
    state.selectedAddress = item
  },
  SET_XIANG_QING: (state, item) => {
    state.xiangqing = item
  },
  SET_GWC_NUM: (state, item) => {
    state.GWCNum = item
  }
}

const actions = {
  // 获取地址列表
  getList({ commit }) {
    return new Promise((resolve, reject) => {
      getAddress({
        page: 1,
        limit: 100
      })
        .then(res => {
          const { result: { records } } = res;
          let data = [];
          records?.map((item) => {
            data.push({
              ...item,
              county: item.region,
              tel: item.mobile,
              postalCode: item.areaCode
            })
          })
          commit('SET_ADDRESS_LIST', data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // 设置地址列表
  setList({ commit, state }, index) {
    commit('SET_SELECTED_ADDRESS', state.addressList[index])
  },
  // 查看商品详情
  seXiangqing({ commit, state },item) {
    localStorage.setItem('xiangqing',JSON.stringify(item))
    commit('SET_XIANG_QING', item)
  },
  seGWCNum({ commit, state },item) {

    commit('SET_GWC_NUM', item)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
